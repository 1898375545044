<template>
  <div class="filters-form">
    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">{{ labels.link_status_f }}</div>
        <div class="form-inline">
          <div>
            <input-select
              v-model="link_status_f"
              @changeValue="changeValueLink_status_f"
            />
          </div>
        </div>
      </div>
      <div class="form-column">
        <div class="form-label">{{ labels.link_builder_f }}</div>
        <div class="form-inline">
          <div>
            <input-select
              v-model="link_builder_f"
              @changeValue="changeValueLink_builder_f"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">{{ labels.loss_reason_f }}</div>
        <div class="form-inline">
          <div>
            <input-select
              v-model="loss_reason_f"
              @changeValue="changeValueLoss_reason_f"
            />
          </div>
        </div>
      </div>

      <div class="form-column">
        <div class="form-label">{{ labels.partner_status_f }}</div>
        <div class="form-inline">
          <div>
            <input-select
              v-model="partner_status_f"
              @changeValue="changeValuePartner_status_f"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">{{ labels.published_links_main_f_from }}</div>
        <div class="form-inline">
          <div><input-number v-model="published_links_main_f_from" /></div>
          <div><input-number v-model="published_links_main_f_to" /></div>
        </div>
      </div>
      <div class="form-column">
        <div class="form-label">{{ labels.pending_links_main_f_from }}</div>
        <div class="form-inline">
          <div><input-number v-model="pending_links_main_f_from" /></div>
          <div><input-number v-model="pending_links_main_f_to" /></div>
        </div>
      </div>
    </div>

    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">{{ labels.link_gap_f_from }}</div>
        <div class="form-inline">
          <div><input-number v-model="link_gap_f_from" /></div>
          <div><input-number v-model="link_gap_f_to" /></div>
        </div>
      </div>

      <div class="form-column">
        <div class="form-column">
          <div class="form-label">
            {{ labels.pending_links_from_do_f_from }}
          </div>
          <div class="form-inline">
            <div><input-number v-model="pending_links_from_do_f_from" /></div>
            <div><input-number v-model="pending_links_from_do_f_to" /></div>
          </div>
        </div>
      </div>
    </div>

    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">{{ labels.date_created_f_from }}</div>
        <div class="form-inline">
          <div><input-date-picker v-model="date_created_f_from" /></div>
          <div><input-date-picker v-model="date_created_f_to" /></div>
        </div>
      </div>
      <div class="form-column">
        <div class="form-label">{{ labels.date_published_f_from }}</div>
        <div class="form-inline">
          <div><input-date-picker v-model="date_published_f_from" /></div>
          <div><input-date-picker v-model="date_published_f_to" /></div>
        </div>
      </div>
    </div>

    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">{{ labels.date_requested_f_from }}</div>
        <div class="form-inline">
          <div><input-date-picker v-model="date_requested_f_from" /></div>
          <div><input-date-picker v-model="date_requested_f_to" /></div>
        </div>
      </div>

      <div class="form-column">
        <div class="form-label">{{ labels.date_status_changed_f_from }}</div>
        <div class="form-inline">
          <div><input-date-picker v-model="date_status_changed_f_from" /></div>
          <div><input-date-picker v-model="date_status_changed_f_to" /></div>
        </div>
      </div>
    </div>

    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">{{ labels.cost_f_from }}</div>
        <div class="form-inline">
          <div><input-number v-model="cost_f_from" /></div>
          <div><input-number v-model="cost_f_to" /></div>
        </div>
      </div>
      <div class="form-column">
        <div class="form-label">{{ labels.p2p_f }}</div>
        <div class="form-inline">
          <div>
            <input-select v-model="p2p_f" @changeValue="changeValueP2p_f" />
          </div>
        </div>
      </div>
    </div>

    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">{{ labels.parser_status_f }}</div>
        <div class="form-inline">
          <div>
            <input-select
              v-model="parser_status_f"
              @changeValue="changeValueParser_status_f"
            />
          </div>
        </div>
      </div>
      <div class="form-column">
        <div class="form-label">{{ labels.slack_community_f }}</div>
        <div class="form-inline">
          <div>
            <input-select
              v-model="slack_community_f"
              @changeValue="changeValueSlack_community_f"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="filters-row">
      <div class="form-column">
        <div class="form-label">{{ labels.slack_community_status_f }}</div>
        <div class="form-inline">
          <div>
            <input-select
              v-model="slack_community_status_f"
              @changeValue="changeValueSlack_community_status_f"
            />
          </div>
        </div>
      </div>
      <div class="form-column"></div>
    </div>
  </div>
</template>

<script>
import InputDatePicker from '@/components/inputs/InputDatePicker'
import InputSelect from '@/components/inputs/InputSelect'
import InputNumber from '@/components/inputs/InputNumber'
import { fetchData, urls } from '@/utils/urls.js'
import { filtersMixin } from '@/utils/filters-mixin'

export default {
  name: 'SetFiltersLinks',
  mixins: [filtersMixin],
  components: { InputDatePicker, InputSelect, InputNumber },
  props: {
    groupToFilter: { default: () => ({}), type: Object },
  },

  data() {
    return {
      date_requested_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'date',
      },
      date_requested_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'date',
      },

      date_created_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'date',
      },
      date_created_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'date',
      },
      date_published_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'date',
      },
      date_published_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'date',
      },
      link_status_f: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: true, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: true, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
      },

      partner_status_f: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: true, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: true, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
      },

      parser_status_f: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: true, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: true, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
      },

      loss_reason_f: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: true, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: true, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
      },

      published_links_main_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },
      published_links_main_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },
      pending_links_main_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },
      pending_links_main_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },

      pending_links_from_do_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },
      pending_links_from_do_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },
      link_builder_f: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: true, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: true, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
      },

      slack_community_status_f: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: true, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: true, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          // Данные для выпадающего списка
        ],
      },

      link_gap_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },

      link_gap_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },

      cost_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },

      cost_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'number',
      },

      p2p_f: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: true, // Валидно ли поле
        clearable: true, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: true, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          { id: 'empty', name: 'Empty' },
          { id: 'Yes', name: 'Yes' },
          { id: 'No', name: 'No' },
        ],
      },

      date_status_changed_f_from: {
        placeholder: 'From',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'date',
      },
      date_status_changed_f_to: {
        placeholder: 'To',
        value: null,
        isValid: true,
        clearable: true,
        required: false,
        readonly: false,
        errorKey: '',
        class: null,
        type: 'date',
      },

      slack_community_f: {
        value: [], // Выбранные значения. Если ничего не выбрано передавать пустой массив
        isValid: true, // Валидно ли поле
        clearable: false, // Показывает значок очистки
        required: false, // Обязательность
        readonly: false, // Редактируемость
        isMultiselect: false, // Возможность множественного выбора
        isTags: false, // Показывать в виде тегов
        isLiveSearch: true, // Показывать возможность фильтрации по строке
        dataList: [
          { id: 'Yes', name: 'Yes' },
          { id: 'No', name: 'No' },
        ],
      },

      labels: {
        date_created_f_from: 'Date created',
        date_created_f_to: 'Date created',
        date_published_f_from: 'Date published',
        date_published_f_to: 'Date published',
        link_status_f: 'Link status',
        link_builder_f: 'Link builder',
        published_links_main_f_from:
          this.groupToFilter?.published_links_main_f_from?.name,
        published_links_main_f_to:
          this.groupToFilter?.published_links_main_f_to?.name,
        pending_links_main_f_from:
          this.groupToFilter?.pending_links_main_f_from?.name,
        pending_links_main_f_to:
          this.groupToFilter?.pending_links_main_f_to?.name,
        link_gap_f_from: 'Link Gap',
        link_gap_f_to: 'Link Gap',
        partner_status_f: 'Partner Status',
        parser_status_f: 'Parser`s Status',
        loss_reason_f: 'Loss Reason',

        pending_links_from_do_f_from: 'Pending links from DO',
        pending_links_from_do_f_to: 'Pending links from DO',

        date_requested_f_from: 'Date requested',
        date_requested_f_to: 'Date requested',
        cost_f_from: 'Cost',
        cost_f_to: 'Cost',
        p2p_f: 'P2P',
        date_status_changed_f_from: 'Date Status changed',
        date_status_changed_f_to: 'Date Status changed',
        slack_community_status_f: 'Slack Community status',
        slack_community_f: 'Slack Community',
      },
    }
  },

  created() {
    this.fetchDataLink_status_f()
    this.fetchDataParser_status_f()
    this.fetchDataLink_builder_f()
    this.fetchDataPartner_status_f()
    this.fetchDataLoss_reason_f()
    this.fetchDataSlack_community_status_f()

    for (let key in this.filters) {
      const element = this.filters[key]
      if (key in this.$data) {
        this.$data[key].value = element
      }
    }
  },

  methods: {
    changeValueSlack_community_f(value) {
      if (value?.length)
        this.$emit('setFilterValue', {
          key: 'slack_community_f',
          value: value,
          group: this.labels.slack_community_f,
        })
      else
        this.$emit('setFilterValue', {
          key: 'slack_community_f',
          value: null,
          group: this.labels.slack_community_f,
        })
    },

    changeValueP2p_f(value) {
      if (value?.length)
        this.$emit('setFilterValue', { key: 'p2p_f', value: value })
      else this.$emit('setFilterValue', { key: 'p2p_f', value: null })
    },

    changeValuePartner_status_f(value) {
      if (value?.length)
        this.$emit('setFilterValue', { key: 'partner_status_f', value: value })
      else
        this.$emit('setFilterValue', { key: 'partner_status_f', value: null })
    },
    changeValueParser_status_f(value) {
      if (value?.length)
        this.$emit('setFilterValue', { key: 'parser_status_f', value: value })
      else this.$emit('setFilterValue', { key: 'parser_status_f', value: null })
    },

    changeValueLoss_reason_f(value) {
      if (value?.length)
        this.$emit('setFilterValue', { key: 'loss_reason_f', value: value })
      else this.$emit('setFilterValue', { key: 'loss_reason_f', value: null })
    },

    changeValueLink_status_f(value) {
      if (value?.length)
        this.$emit('setFilterValue', { key: 'link_status_f', value: value })
      else this.$emit('setFilterValue', { key: 'link_status_f', value: null })
    },

    changeValueLink_builder_f(value) {
      if (value?.length)
        this.$emit('setFilterValue', {
          key: 'link_builder_f',
          value: value,
          group: this.labels.link_builder_f,
        })
      else
        this.$emit('setFilterValue', {
          key: 'link_builder_f',
          value: null,
          group: this.labels.link_builder_f,
        })
    },

    changeValueSlack_community_status_f(value) {
      if (value?.length)
        this.$emit('setFilterValue', {
          key: 'slack_community_status_f',
          value: value,
          group: this.labels.slack_community_status_f,
        })
      else
        this.$emit('setFilterValue', {
          key: 'slack_community_status_f',
          value: null,
          group: this.labels.slack_community_status_f,
        })
    },

    async fetchDataParser_status_f() {
      let query = `page=1&per_page=all&link_status=asc`
      let result = await fetchData(urls.parserStatuser, 'GET', null, query)

      this.parser_status_f.dataList = (result?.rows || []).map((item) => {
        return {
          id: item.parser_status,
          name: item.parser_status,
        }
      })
    },

    async fetchDataLink_status_f() {
      let query = `page=1&per_page=all&link_status=asc`
      let result = await fetchData(urls.linkStatuses, 'GET', null, query)

      this.link_status_f.dataList = (result?.rows || []).map((item) => {
        return {
          id: item.link_status,
          name: item.link_status,
        }
      })
    },

    async fetchDataPartner_status_f() {
      let query = `page=1&per_page=all&partner_status=asc`
      let result = await fetchData(urls.partnerStatuses, 'GET', null, query)

      this.partner_status_f.dataList = (result?.rows || []).map((item) => {
        return {
          id: item.partner_status,
          name: item.partner_status,
        }
      })
    },

    async fetchDataLoss_reason_f() {
      let query = `page=1&per_page=all&reasons=asc`
      let result = await fetchData(urls.reasons, 'GET', null, query)

      this.loss_reason_f.dataList = (result?.rows || []).map((item) => {
        return {
          id: item.reasons,
          name: item.reasons,
        }
      })
    },

    async fetchDataLink_builder_f() {
      let query = `page=1&per_page=all&email=asc`
      let result = await fetchData(urls.users, 'GET', null, query)

      this.link_builder_f.dataList = (result?.rows || []).map((item) => {
        return {
          id: item.email,
          name: item.email,
        }
      })
    },

    async fetchDataSlack_community_status_f() {
      let query = `page=1&per_page=all&slack_community_status=asc`
      let result = await fetchData(
        urls.slackCommunityStatus,
        'GET',
        null,
        query
      )

      this.slack_community_status_f.dataList = (result?.rows || []).map(
        (item) => {
          return {
            id: item.slack_community_status,
            name: item.slack_community_status,
          }
        }
      )
    },
  },
}
</script>
